import { createSelector } from 'reselect'

import { getAuth as getState } from './rootSelectors'

const ECB_CUSTOMER_ID = 50555

export const getUser = createSelector(getState, (authState) => authState.user)

export const getIsECBUser = createSelector(
  getState,
  (authState) => authState?.user?.id_customer === ECB_CUSTOMER_ID || authState?.user?.customer_id === ECB_CUSTOMER_ID,
)

export const getImpersonator = createSelector(getState, (authState) => authState.impersonator)

export const isLoadingImpersonation = createSelector(getState, (authState) => authState.loadingImpersonation)

export const isUserAdmin = createSelector(getState, (authState) => {
  return authState.user?.privileges_index === 2
})

export const isUserAdminOrAdvanced = createSelector(
  getState,
  (authState) => authState?.user?.privileges_index && authState.user.privileges_index >= 1,
)

export const getUserEmail = createSelector(getUser, (user) => user?.email)

export const haveServerTime = createSelector(getState, (authState) => authState.gotServerTime)
